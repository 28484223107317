.enabled {
  cursor: pointer;
}

.base {
  text-decoration: inherit;
  color: inherit;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
}
