@import '@kwara/components/src/foundations/_variables.scss';

.default {
  padding-right: 45px;
}

.compact {
  padding-right: 0;
}

.checkbox > input[type='checkbox'] {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
}

.checkbox > label {
  cursor: pointer;
}

.checkbox > label:before {
  box-sizing: border-box;

  flex: none;

  content: '';

  display: inline-block;
  width: 1rem;
  height: 1rem;

  margin-right: 16px;

  border: 1px solid brand-col(grey-400);
  border-radius: 5px;
}

.checkbox.regular > label:before {
  width: 18px;
  height: 18px;
  border-radius: 5px;
}

.checkbox.medium > label:before {
  width: 22px;
  height: 22px;
  border-radius: 7px;
}

.checkbox > input[type='checkbox']:checked + label:before {
  border-color: brand-col(indigo-500);

  /* This should somehow be imported from Assets.Checkbox */
  background: brand-col(indigo-500)
    url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%229%22%20height%3D%228%22%20viewBox%3D%220%200%209%208%22%3E%0A%20%20%3Cpath%20fill%3D%22%23FFF%22%20fill-rule%3D%22evenodd%22%20d%3D%22M7.2%2C0.400058852%20C7.53137085%2C-0.0417689481%208.1581722%2C-0.131311998%208.6%2C0.200058852%20C9.0418278%2C0.531429702%209.13137085%2C1.15823105%208.8%2C1.60005885%20L4.3%2C7.60005885%20C3.93487039%2C8.08689833%203.22320259%2C8.137475%202.79289322%2C7.70716563%20L0.292893219%2C5.20716563%20C-0.0976310729%2C4.81664134%20-0.0976310729%2C4.18347636%200.292893219%2C3.79295207%20C0.683417511%2C3.40242778%201.31658249%2C3.40242778%201.70710678%2C3.79295207%20L3.39180581%2C5.4776511%20L7.2%2C0.400058852%20Z%22/%3E%0A%3C/svg%3E%0A')
    no-repeat center center;
}

.checkbox > input[type='checkbox']:focus + label:before {
  border-color: brand-col(indigo-500);
  border-width: 2px;
}

.checkbox > input[type='checkbox']:not(:checked):disabled + label:before {
  border-color: brand-col(light-grey-500);
}

.checkbox > input[type='checkbox']:checked:disabled + label:before {
  opacity: 0.15;
}
