@import 'variables';

@mixin define-col($name, $col) {
  .#{$name} {
    color: $col;
  }
  .bg-#{$name} {
    background-color: $col;
  }
  .b--#{$name} {
    border-color: $col;
  }
  .bf--#{$name}:focus {
    border-color: $col;
  }
  .bfw--#{$name}:focus-within {
    border-color: $col;
  }
  /* focus-within polyfill */
  .bfw--#{$name}.ally-focus-within {
    border-color: $col;
  }

  .visited-#{$name}:visited,
  .link-#{$name}:link,
  .hover-#{$name}:hover,
  .hover-#{$name}:focus {
    color: $col;
  }

  .hover-bg-#{$name}:hover,
  .focus-bg-#{$name}:focus {
    background-color: $col;
  }
}

@each $name, $col in $cols {
  @include define-col($name, $col);
}
