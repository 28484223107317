.info {
  padding-top: 8px;
}

.required {
  margin-left: 3px;
}

.required:after {
  content: '*';
}
