@import '@kwara/components/src/foundations/_variables.scss';

.isFull {
  box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.04);
  border: 1px solid transparent;
  border-radius: 12px;
}
.isFull.isHoverable {
  transition: all 0.3s;

  &:hover {
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.1);
    transform: scale(1.05);
    cursor: pointer;
  }
}

.isNotFull {
  box-shadow: 0 0 4px 0 rgba(237, 135, 121, 0.04),
    0 -1px 1px 0 rgba(0, 0, 0, 0.02), 0 0 4px 0 rgba(0, 0, 0, 0.02);
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-radius: 24px 24px 0 0;
}

.isHighlighted {
  border: 2px solid brand-col('indigo-500');
}
