@import '@kwara/components/src/foundations/_variables.scss';

.TooltipWrapper {
  position: relative;
  cursor: pointer;
}

.Tooltip {
  --tooltip-width: 120px;
  visibility: hidden;
  width: var(--tooltip-width);
  position: absolute;
  text-align: center;
  background-color: transparentize(brand-col(black), 0.5);
  bottom: 180%;
  left: 50%;
  margin-left: calc(-1 * var(--tooltip-width) / 2);
}

.Tooltip:after {
  --tooltip-arrow-pointness: 5px;
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: calc(-1 * var(--tooltip-arrow-pointness));
  border-width: var(--tooltip-arrow-pointness);
  border-style: solid;
  border-color: transparentize(brand-col(black), 0.5) transparent transparent
    transparent;
}

.TooltipWrapper:hover .Tooltip {
  visibility: visible;
}
