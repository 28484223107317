@import '@kwara/components/src/foundations/_variables.scss';

.regular {
  height: 40px;
  border-radius: 6px;
  padding: 0 12px;
}

.medium {
  height: 48px;
  border-radius: 8px;
  padding: 0 16px;
}

.Input {
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;

  outline: none;
}

.Container {
  border-color: brand-col('light-grey-500');
  background: white;
}

.Container.disabled {
  border-color: brand-col('light-grey-300');
}

.Container:focus-within,
.Container.ally-focus-within {
  border-color: brand-col('indigo-500');
}

.Container.error {
  border-color: brand-col('red-500');
}

.Input::placeholder {
  color: brand-col('grey-300');
}

.leftGlyph,
.rightGlyph {
  pointer-events: none;
}

.hasGlyph.regular .leftGlyph {
  margin-right: 12px;
}

.hasGlyph.regular .rightGlyph {
  margin-left: 12px;
}

.hasGlyph.medium .leftGlyph {
  margin-right: 16px;
}

.hasGlyph.medium .rightGlyph {
  margin-left: 16px;
}

.ClearButton {
  line-height: 0;
  pointer-events: all;
}
