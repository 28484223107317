.container {
  height: 100%;
}

.navigationInner,
.content {
  width: 100vw;
  max-width: 100%;
  margin: 0 auto;
}

.inflexible .navigationInner,
.inflexible .content {
  max-width: 1280px;
  min-width: 979px;
}

.content {
  flex-grow: 1;
  flex-shrink: 1;
}
