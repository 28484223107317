.row > * > :first-child {
  width: 60%;
  flex: 0 0 auto;
}

.textSelect > :first-child {
  width: 60%;
  margin-right: 16px;
  flex-shrink: 0;
}
