.Section {
  padding: 24px 0;
}

.Section:last-child {
  border-bottom: none;
}

.Heading {
  min-width: 144px;
}

.EditColumn {
  flex: 1;
  text-align: right;
}

.RecordContent {
  flex: 3;
}
