/* Brand colours */
$cols: (
  indigo-600: rgb(57, 73, 171),
  indigo-500: rgb(69, 103, 186),
  indigo-100: rgb(216, 220, 235),
  indigo-50: rgb(240, 242, 247),
  green-600: rgb(115, 232, 90),
  red-700: rgb(92, 44, 38),
  red-600: rgb(232, 109, 90),
  red-500: rgb(237, 135, 121),
  red-100: rgb(242, 209, 206),
  red-50: rgb(250, 240, 240),
  teal-700: rgb(42, 69, 69),
  teal-600: rgb(54, 90, 90),
  teal-500: rgb(114, 149, 150),
  teal-200: rgb(168, 200, 201),
  teal-100: rgb(206, 224, 224),
  teal-50: rgb(237, 245, 245),
  amber-700: rgb(97, 65, 26),
  amber-500: rgb(251, 189, 80),
  amber-100: rgb(255, 236, 207),
  grey-500: rgba(0, 0, 0, 0.84),
  grey-400: rgba(0, 0, 0, 0.54),
  grey-300: rgba(0, 0, 0, 0.34),
  light-grey-500: rgba(0, 0, 0, 0.15),
  light-grey-400: rgba(0, 0, 0, 0.07),
  light-grey-300: rgba(0, 0, 0, 0.04),
  light-grey-200: rgba(0, 0, 0, 0.02),
  semi-white-500: rgb(245, 245, 245),
  semi-white-400: rgb(250, 250, 250),
  modal-background: rgba(255, 255, 255, 0.95),
  white: #fff,
  black: #000
);

@function brand-col($name) {
  @return map-get($cols, $name);
}
