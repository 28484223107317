@import '../foundations/mixins';

.Overview {
  min-height: 350px;

  margin-left: 16px;
  margin-right: 16px;

  @include respond-to(viewport-12) {
    padding-left: 88px;
    padding-right: 88px;
  }
}

.Content {
  margin: 30px 30px;
  @include respond-to(viewport-12) {
    padding: 24px 88px;
  }
}
