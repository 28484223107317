@import '@kwara/components/src/foundations/_variables.scss';

.Wrapper {
  position: relative;
  cursor: pointer;
}

.Icon {
  --size: 1rem;
  width: var(--size);
  height: var(--size);
  font-size: 0.55rem;
  color: black;
  box-sizing: border-box;
  border-width: 1px;
  font-weight: bold;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.InfoPanel {
  visibility: hidden;
  position: absolute;
  text-align: center;
  background-color: brand-col(white);
  top: -85%;
  left: calc(100% + 10px);
  color: black;
}

.InfoPanel:after {
  --tooltip-arrow-pointness: 8px;
  content: '';
  position: absolute;
  top: 20px;
  right: 100%;
  margin-top: calc(-1 * var(--tooltip-arrow-pointness));
  border-width: var(--tooltip-arrow-pointness);
  border-style: solid;
  border-color: transparent brand-col(white) transparent transparent;
}

.Wrapper:hover .InfoPanel {
  visibility: visible;
}
