/*
  Global styles
*/

body {
  margin: 0;
  padding: 0;
}

/*
  Set all containers to be the height of the viewport
*/
html,
body,
#root {
  height: 100%;
}

@media print {

  .hide-on-print,
  .hide-on-print * {
    display: none !important;
  }
}

@media not print {
  .show-on-print {
    display: none !important;
    visibility: hidden !important;
  }
}

/* Make sure adding "hidden" via attributes really hides the element
   see: https://github.com/twbs/bootstrap/issues/15274
*/
[hidden] {
  display: none !important;
}

.t-1a {
  transition: all .5s;
}
