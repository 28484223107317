.container {
  display: inline-block;
  box-sizing: border-box;
  white-space: nowrap;
}

.hasBackground {
  min-height: 24px;
  padding: 3px 5px;
  border-radius: 4px;
}
