@import '@kwara/components/src/foundations/_variables.scss';

.Wrapper {
  display: flex;
  justify-content: space-between;
  background: brand-col('semi-white-500');
}

.Dropzone {
  border: 1px dashed grey;
  padding: 90px 20px;
  position: relative;
  text-align: center;
  display: block;
  transition: background .4s;
}

.DropzoneDirty {
  background: transparentize(brand-col('indigo-500'), 0.8);
}

.InputFile {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  left: 0;
  z-index: 2;
  top: 0;
}
