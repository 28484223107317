.primary:not(.disabled):focus,
.primary:not(.disabled):hover {
  background-color: rgb(73, 115, 196);
}

.primary:not(.disabled):active {
  background-color: rgb(64, 101, 173);
}

.tiny {
  height: 18px;
  padding-bottom: 2px;
}

.small {
  height: 32px;
  padding: 0 14px;
}

.regular {
  height: 40px;
  padding: 0 18px;
}

.medium {
  height: 48px;
  padding: 0 22px;
}

.large {
  height: 50px;
  padding: 0 58px;
}

.glyphOnly {
  padding: 0 1px 0 9px;
}

.glyphLeft {
  margin-right: 8px;
}

.glyphRight {
  margin-left: 8px;
}

.regular .glyphLeft,
.regular .glyphRight {
  width: 18px;
  height: auto;
}

.medium .glyphLeft,
.medium .glyphRight {
  width: 24px;
  height: auto;
}

.enabled {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
