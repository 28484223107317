//Screen Resolution Stats - https://gs.statcounter.com/screen-resolution-stats#monthly-201801-202003-bar

// (default)  Mobile-portrait
// 480px	    Mobile-landscape & up
// 768px	    Tablet-portrait & up
// 992px	    Tablet-landscape & up
// 1200px	    Laptops & up

$breakpoints: (
    'viewport-4': 480px,
    'viewport-7': 768px,
    'viewport-9': 992px,
    'viewport-12': 1200px,    
);

@mixin respond-to($breakpoint) {
    // Retrieves the value from the key
    $value: map-get($breakpoints, $breakpoint);
  
    // If the key exists in the map
    @if $value != null {
      // Prints a media query based on the value
      @media (min-width: $value) {
        @content;
      }
    }
  
    // If the key doesn't exist in the map
    @else {
      @warn "No value could be retrieved from `#{$breakpoint}`. "
          + "Please make sure it is defined in `$breakpoints` map.";
    }
  }

@mixin kw-text($name, $size, $line-height) {
  .kw-text-#{$name} {
    font-size: $size;
    font-weight: normal;
    letter-spacing: 0;
    line-height: $line-height;
  }
}