.OverflowScroll {
  overflow: scroll;
}

.SubStep {
  width: 100%;
  height: 100%;
  /* WizardActions height */
}

.SubStepBottomMargin {
  margin-bottom: 81px;
}

.WizardSubstep .Substep {
  padding-top: 72px;
}

.SubStep.actionsHidden {
  margin-bottom: 0;
}

.SubStepInner {
  margin: 0 auto;
}

.WizardActionsFixed {
  position: absolute;
}

.WizardActions {
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
}

.WizardActionsInner {
  width: 100%;
  max-width: 518px;
}
