@import '@kwara/components/src/foundations/_variables.scss';

.Container {
  width: 384px;
  padding-top: 21px;
}

.good .Inner {
  background-color: brand-col(teal-200);
}

.bad .Inner {
  background-color: brand-col(red-100);
}

.Inner {
  box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  border: 1px solid transparent;

  text-align: center;

  overflow: visible;
}

.Inner:before {
  content: ' ';
  display: block;
  background-repeat: no-repeat;
  background-size: 298px 240px;
  width: 298px;
  height: 240px;
  transform: translateY(-21px);
}

.good .Inner:before {
  background-image: url('./good.png');
}

.bad .Inner:before {
  background-image: url('./bad.png');
}
