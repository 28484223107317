@import '@kwara/components/src/foundations/_variables.scss';

.container {
  position: relative;
}

.menuContainer {
  position: absolute;
  top: 0;
  left: 0;
}

.menu {
  background-color: white;
  box-shadow: 0 0 0 1px brand-col(light-grey-300),
    0 1px 7px 0 brand-col(light-grey-300);
  border-radius: 8px;
  margin: 5px 10px;
  padding: 8px 1px;
  overflow: hidden;
}

.menu .menuItem {
  cursor: pointer;
  padding: 0;
}

.menu .menuItem,
.menu .menuItem > * {
  color: brand-col(grey-500);
  text-decoration: none;
}

.menuItem:hover,
.menuItem:global(.rc-menu-item-selected) {
  background-color: brand-col(semi-white-500);
}

.menuItem > * {
  display: block;
  padding: 10px 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menuItem[aria-disabled='true'] {
  opacity: 0.6;
  cursor: not-allowed;
}
