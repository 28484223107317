@import '@kwara/components/src/foundations/_variables.scss';
@import '@kwara/components/src/foundations/_mixins.scss';

.Box {
  position: relative;
}

.Box:before {
  content: '';
  position: absolute;
  top: 28px;
  bottom: 28px;
  left: 0;
  right: 0;

  border: 1px solid transparent;
  border-radius: 12px;
  background-color: brand-col(semi-white-400);
}

.Box > * {
  z-index: 1;
}

.Message {
  padding: 2rem 0 2rem 2rem;
  @include respond-to(viewport-4) {
    padding: 2rem;
  }
}

.Image {
  width: 25vw;
  max-height: auto;
  margin-top: 1rem;

  @include respond-to(viewport-4) {
    margin-left: auto;
    max-width: 268px;
    max-height: 200px;
  }
}
