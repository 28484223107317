.Container {
  display: grid;
  grid-template-columns: 100%;
  height: 100vh;
}

@media (min-width: 700px) {
  .Container {
    grid-template-columns: 50% 50%;
  }
}

.Image {
  background-image: url('./background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center right;
  min-height: 90px;
}

.Container__Member .Image {
  background-image: url('./background-member.png');
}

.Logotype {
  width: 36px;
  height: 36px;
  top: 30px;
  left: 30px;
  position: relative;
}

.FormWrap {
  /* Stops background-image jumping when validation errors appear */
  min-height: 1400px;
  justify-content: center;
}

@media (min-width: 700px) {
  .Container__Member .FormWrap {
    grid-column: 1;
    grid-row: 1;
  }
}

@media (min-width: 1000px) {
  .FormWrap {
    justify-content: unset;
  }
}

.Form {
  width: 100%;
}

.Content {
  margin: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
