.container {
  width: 300px;
  position: fixed;
  top: 64px;
  right: 10px;

  overflow: hidden;

  border: 1px solid rgba(0, 0, 0, 0.07);
}

.container:hover {
  opacity: 1;
}

.container .menu {
  display: none;
}

/* Open panel on hover or when switching */
.container:hover .menu,
.container.isSwitching .menu {
  display: block;
}

.isSwitching {
  pointer-events: none;
}

.isSwitching .accounts {
  opacity: 0;
}

.switchingMessage {
  display: none;
}

.isSwitching .switchingMessage {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.accounts {
  padding: 0;
  height: 300px;
  overflow: scroll;
  border-top: 1px solid rgba(0, 0, 0, 0.07);
}

.account {
  list-style: none;
}

.account:first-child {
  border-top: none;
}

.account button {
  display: block;
  width: 100%;
  height: 100%;

  background: none;
  border: none;
  cursor: pointer;

  text-align: left;

  padding: 1rem;
}

.account .title,
.account .description {
  margin: 0;
  padding: 0;
}
