@import '@kwara/components/src/foundations/_variables.scss';

.Asset {
  line-height: 0;
  width: auto;
  height: auto;
}

.AssetSmall {
  line-height: 0;
  width: 18px;
  height: 18px;
}

.Asset svg,
.AssetSmall svg {
  width: inherit;
  height: inherit;
}

.white svg g {
  fill: #fff;
}

.grey300 svg g {
  fill: brand-col('grey-300');
}

.amber500 svg g {
  fill: brand-col('amber-500');
}

.amber700 svg g {
  fill: brand-col('amber-700');
}

.red500 svg g {
  fill: brand-col('red-500');
}

.red700 svg g {
  fill: brand-col('red-700');
}

.teal500 svg g {
  fill: brand-col('teal-500');
}

.teal700 svg g {
  fill: brand-col('teal-700');
}

.indigo500 svg g,
.indigo500 svg path {
  fill: brand-col('indigo-500');
}

.black svg g {
  fill: #000;
}

.Spinner {
  animation-name: spin;
  animation-duration: 0.7s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
