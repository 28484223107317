.ProfilePhotoInput {
  display: none;
}

.ProfilePhotoWrapper {
  cursor: pointer;
}

.ProfilePhotoLabel {
  cursor: pointer;
  position: relative;
  display: block;
  /* Border-radius has to match value in ProfilePhoto/css */
  border-radius: 8px;
  background: #000;
}

.LinkContainer {
  position: relative;
  display: block;
}

.InputText {
  z-index: 1;
  position: relative;
}

.OverlayText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  color: #fff;
}

.ProfilePhotoLabel:hover .OverlayText {
  opacity: 1;
}

.ProfilePhotoLabel:hover .ProfilePhotoWrapper {
  opacity: 0.4;
}
