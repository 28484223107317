@import '@kwara/components/src/foundations/_variables.scss';

.Modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
}

.ModalOverlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: brand-col('modal-background');
  width: 100vw;
  height: 100vh;
}

/*
  Prevents page body from scrolling
  when Modal is open
*/
:global .ReactModal__Body--open {
  position: fixed;
  overflow: auto;
}
