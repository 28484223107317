@import 'mixins';
/* Foundation typography sizes and line-heights */

@font-face {
  font-family: 'Brown';
  src: url(./fonts/Brown-Regular.otf);
  font-weight: normal;
}

@font-face {
  font-family: 'Brown';
  src: url(./fonts/Brown-Bold.otf);
  font-weight: bold;
}

@font-face {
  font-family: 'Brown';
  src: url(./fonts/Brown-Light.otf);
  font-weight: 300;
}

body {
  font-family: 'Brown', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';

  font-size: 16px;
  font-weight: normal;

  letter-spacing: 0;
  line-height: 22px;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
}

@include kw-text(huge, 64px, 64px);
@include kw-text(extra-big, 48px, 52px);
@include kw-text(big, 36px, 44px);
@include kw-text(super-large, 26px, 36px);
@include kw-text(extra-large, 24px, 32px);
@include kw-text(large, 21px, 30px);
@include kw-text(medium, 18px, 26px);
@include kw-text(regular, 16px, 22px);
@include kw-text(small, 14px, 18px);

.kw-weight-light {
  font-weight: 300;
}

.kw-weight-regular {
  font-weight: normal;
}

.kw-weight-bold {
  font-weight: bold;
}

.kw-numeric {
  font-variant-numeric: slashed-zero;
}