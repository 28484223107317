@import '@kwara/components/src/foundations/_variables.scss';

.regular {
  height: 40px;
  border-radius: 6px;
  padding: 0 12px;
}

.medium {
  height: 48px;
  border-radius: 8px;
  padding: 0 16px;
}

.container {
  padding-right: 45px;
}

.container>.input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed; // avoid jumping to the top on click, see https://stackoverflow.com/a/54770732/1446845
}

.container>.labelling {
  cursor: pointer;
}

.container>.labelling:before {
  box-sizing: border-box;

  content: '';

  display: inline-block;
  width: 1rem;
  height: 1rem;

  margin-right: 16px;

  border: 1px solid brand-col(grey-400);
  border-radius: 50%;
}

.container>.input:focus+.labelling:before {
  border-color: brand-col(indigo-500);
  border-width: 2px;
}

.container>.input:checked+.labelling:before {
  border: 6px solid brand-col(indigo-500);
}

.container>.input:disabled+.labelling:before {
  border-color: brand-col(light-grey-500);
}
