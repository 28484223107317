.ActionButton {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  cursor: pointer;
}

.ActionButtonTiny {
  width: 22px;
  height: 22px;
  border: 0;
  border-radius: 100%;
  cursor: pointer;
}

.disabled {
  cursor: default;
  opacity: 40%;
}
