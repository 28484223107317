@import '@kwara/components/src/foundations/_mixins.scss';

.container {
  flex-shrink: 0;
}

.mobileProgress {
  @include respond-to(viewport-9) {
    display: none;
  }
}
