.Inbox {
  width: 312px;
  padding-right: 72px;
}

.Overview {
  padding-left: 48px;
}

.ActionsBar > * {
  margin-left: 16px;
}
