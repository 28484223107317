.Section {
  page-break-after: always;
}

.Title {
  margin-top: -1px;
  /* To overlap grey border of box */
}

.Header {
  display: flex;
}

.HeaderRight {
  margin-left: auto;
}

.Secondary {
  width: 200px;
}
