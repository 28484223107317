@import '@kwara/components/src/foundations/_mixins.scss';
@import '@kwara/components/src/foundations/_variables.scss';

.Container {
  display: grid;
  height: 100vh;
  grid-template-columns: 100%;
  @include respond-to(viewport-7) {
    grid-template-columns: 50% 50%;
  }
}

.Container__Member {
  @include respond-to(viewport-7) {
    grid-column: 1;
    grid-row: 1;
  }
}

.Image {
  background-image: url('@kwara/components/src/LogIn/background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center right;
  min-height: 90px;
}

.Container__Member .Image {
  background-image: url('@kwara/components/src/LogIn/background-member.png');
}

.Logotype {
  width: 36px;
  height: 36px;
  top: 30px;
  left: 30px;
  position: relative;
}

.Content {
  display: grid;
  align-items: end;
}

.Content__Member {
  @include respond-to(viewport-7) {
    grid-column: 1;
    grid-row: 1;
  }
}

.Link {
  border-bottom: 1px solid brand-col('grey-300');
  width: max-content;
}
