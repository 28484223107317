.base {
  outline: none;
}

.isCompact {
}

.isNotCompact {
  min-height: 72px;
}

.regular {
  border-radius: 6px;
  padding: 11px 12px;
}

.medium {
  border-radius: 8px;
  padding: 11px 16px;
}
