.EligibilityPanel {
  display: flex;
  flex-wrap: wrap;
  width: 280px;
}

.EligibilityThumbnail {
  flex: 1 1 120px;
}

.EligibilityThumbnail:last-of-type {
  border: none;
}
