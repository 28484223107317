.ProfilePhoto {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.ExpanderOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
}

.ExpandBtn {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.ExpandBtnActive {
  cursor: pointer;
}

.ExpandedImageWrap {
  width: 90%;
}

.ExpandedImage {
  margin: 0 auto;
  display: block;
  max-height: 100vh;
}

.tiny,
.small,
.regular {
  border-radius: 100%;
}

.tiny {
  width: 24px;
  height: 24px;
}

.small {
  width: 40px;
  height: 40px;
}

.regular {
  width: 64px;
  height: 64px;
}

.big {
  width: 100%;
  height: 100%;
  max-height: 600px;
  max-width: 600px;
}

.small img,
.regular {
  background-size: contain;
}

.medium {
  border-radius: 8px;
  width: 158px;
  height: 158px;
}

.paddingMedium {
  padding: 36px 22px;
}
