.ProfilePhoto {
  display: none;
}

.LinkContainer {
  position: relative;
}

.InputText {
  z-index: 1;
  position: relative;
}

.NoteItem {
  margin-top: 0;
}

.TitleSelect {
  display: inline-block;
  min-width: 30%;
}

.IdTypeSelect {
  display: inline-block;
  min-width: 30%;
}
