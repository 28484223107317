@import '@kwara/components/src/foundations/_variables.scss';

.Header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.5rem;
}

.Container {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.ModuleContainer {
  width: 100%;
  padding: 2rem;
  background-color: brand-col(white);
}
