.StatisticRow {
  margin-bottom: 48px;
}

.StatisticRow.compact {
  margin-bottom: 0;
}

.StatisticRow:last-child {
  margin-bottom: 0;
}

.StatisticRow > :last-child {
  margin-right: 0;
}
