.Subsection {
  margin-top: 80px;
}

.Header {
  transform: translateY(-50%);
}

.HeaderRight {
  margin-left: auto;
}
