@import '@kwara/components/src/foundations/_variables.scss';

.GetStartedImg {
  position: relative;
  top: -12px;
  width: 60px;
  left: 10px;
}

.button {
  min-height: 80px;
}

.downloadButton {
  background-color: brand-col('light-grey-300');
}

.uploadButton {
  min-height: 200px;
}

.uploadText {
  margin: auto;
}

.ProductDropdown {
  background-color: brand-col('light-grey-300');
}

.actionIcon,
.ellipsisIcon {
  background: none;
  border: none;
  cursor: pointer;
}

.trashBinIcon {
  transform: scale(0.8);
}

.pencilIcon {
  transform: translate(0, -3px);
}

.actionIconWrapper {
  position: absolute;
  right: 0;
}

.inlineSelect {
  width: 50%;
  margin-top: -20px;
  position: relative;
}
