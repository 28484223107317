@import '@kwara/components/src/foundations/_variables.scss';

.Select {
  /* Hide default arrow */
  -moz-appearance: none;
  -webkit-appearance: none;

  /* background-image set as data URI in Component */
  background-repeat: no-repeat;

  background-color: white;
}

.Select.isNotInline {
  background-position: right 1rem center;

  border: 1px solid brand-col('light-grey-500');
}

.Select.isNotInline:focus {
  outline: none;
  border-color: brand-col('indigo-500');
}

.Select.isNotInline.isError {
  border-color: brand-col('red-500');
}

.Select.isNotInline.isDisabled {
  border-color: brand-col('light-grey-300');
}

.Select.isNotInline.regular {
  height: 40px;
  border-radius: 25px;
  padding: 0 25px 0 18px;

  background-size: 6px 12px;
}

.Select.isNotInline.medium {
  height: 48px;
  border-radius: 25px;
  padding: 0 22px;

  background-size: 8px 14px;
}

.Select.isInline {
  background-position: right center;
  border: none;
  padding-right: 1rem;
}
