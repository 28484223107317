@import '@kwara/components/src/foundations/_mixins.scss';

.Container {
  display: grid;
  grid-template-columns: 100%;
  height: 100vh;
  @include respond-to(viewport-7) {
    grid-template-columns: 50% 50%;
  }
}

.Image {
  background-image: url('./background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center right;
  min-height: 90px;
}

.Container__Member .Image {
  background-image: url('./background-member.png');
}

.Logotype {
  width: 36px;
  height: 36px;
  top: 30px;
  left: 30px;
  position: relative;
}

.FormWrap {
  justify-content: center;
  @include respond-to(viewport-12) {
    justify-content: unset;
  }
}

.Container__Member .FormWrap {
  @include respond-to(viewport-7) {
    grid-column: 1;
    grid-row: 1;
  }
}

.Form {
  width: 100%;
  max-width: 312px;
  @include respond-to(viewport-12) {
    margin-left: 120px;
  }
}
