.Note {
  margin-bottom: 64px;
  padding: 12px;
  border-radius: 12px;
}

.NoteItem {
  border-left-width: 2px;
  border-left-style: solid;
  margin: 0;
  padding: 4px 0 4px 14px;
}
